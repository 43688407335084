import React, { useContext, useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { Checkbox } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import dayjs from "dayjs";

import { isValidPincode } from "../Services/ErrorFunctions";

const FirstPage = () => {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const minDate = moment().subtract(18, "years").endOf("day");

  const { getUserData, profile } = useContext(ProfileContext);

  const navigate = useNavigate();
  const { search } = useLocation();
  const registeredFrom = new URLSearchParams(search).get("registered_from");
  const apiKey = new URLSearchParams(search).get("apiKey");
  const page = new URLSearchParams(search).get("page");
  
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  const defaultDate = moment().subtract(18, "years").format("DD-MM-YYYY");

  const routes = {
    blogs: "/select-stage",
    momnexus: "/select-stage",
    adminPortal: "/sign-up",
    tkc: "/sign-up",
    tkl: "/sign-up",
    brandlabs: "/sign-up",
  };

  useEffect(() => {
    if (!profile.registred_from) {
      getUserData("registred_from", registeredFrom);
    }
  }, [registeredFrom]);

  useEffect(() => {
    if (!profile.apiKey) {
      getUserData("apiKey", apiKey);
    }
  }, [apiKey]);

  useEffect(() => {
    if (!profile.page) {
      getUserData("page", page);
    }
  }, [page]);

  const handleContinue = () => {
    form
      .validateFields()
      .then(() => {
        navigate(routes[profile?.registred_from || routes[registeredFrom]]);
      })
      .catch((errorInfo) => {
        // navigate(routes[profile?.registred_from || routes[registeredFrom]]); //for testing purpose
        console.log("Validation failed:", errorInfo);
      });

    // emitMessage("test2", "http://localhost:3000")
  };

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      form.setFieldsValue({
        Name: profile?.full_name,
        "Date of Birth": profile?.dob ? moment(profile?.dob) : "",
        Gender: profile?.gender,
        Pincode: profile?.pincode,
      });
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          requiredMarkValue: requiredMark,
        }}
        className="w-100"
      >
        <Form.Item
          label="Name"
          name="Name"
          rules={[{ required: true }]}
          validateDebounce={1500}
        >
          <Input
            placeholder="Enter your Name"
            onChange={(e) => getUserData("full_name", e.target.value)}            
          />
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name="Date of Birth"
          tooltip={{
            title: "Your age must be 18+",
            icon: <InfoCircleOutlined />,
          }}
          rules={[{ required: true }]}
          validateDebounce={1500}
        >
          <DatePicker
            onChange={(date, dateString) => getUserData("dob", dateString)}
            disabledDate={(current) => current && current > minDate}
            // defaultPickerValue={defaultDate}
            defaultPickerValue={dayjs(defaultDate, "DD-MM-YYYY")}
            className="w-100"
            placeholder="Enter your Date of Birth"
          />
        </Form.Item>
        <Form.Item
          label="Gender"
          rules={[{ required: true }]}
          name="Gender"
          validateDebounce={1500}
        >
          <Radio.Group onChange={(e) => getUserData("gender", e.target.value)}>
            <Radio value="Female">Female</Radio>
            <Radio value="Male">Male</Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item rules={[{ required: true }]} name="Gender">
          <Select onChange={(e) => getUserData("gender", e)} placeholder="Select your Gender">
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Male">Male</Select.Option>
          </Select>
        </Form.Item> */}
        <Form.Item
          label="Pincode"
          name="Pincode"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (!isValidPincode(value)) {
                  return Promise.reject("Please enter a valid pin code");
                }
                return Promise.resolve();
              },
            },
          ]}
          validateDebounce={1500}
        >
          <Input
            placeholder="Enter your Pincode"
            onChange={(e) => getUserData("pincode", e.target.value)}
          />
        </Form.Item>

        <Checkbox onChange={() => setIsPrivacyChecked(!isPrivacyChecked)}>
          I agree to processing of my personal health data for providing me App
          functions. Check our{" "}
          <a
            href="https://www.beingtheparent.com/privacy-policy/"
            target="_blank"
            className="text-danger"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </Checkbox>
        <Checkbox
          onChange={() => setIsTermsChecked(!isTermsChecked)}
          className="my-3"
        >
          I agree with all the Terms of use & the{" "}
          <a
            href="https://www.beingtheparent.com/terms-of-service/"
            target="_blank"
            className="text-danger"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </Checkbox>
        <Form.Item>
          <Button
            type="primary"
            className="w-100"
            danger
            onClick={handleContinue}
            disabled={!isPrivacyChecked || !isTermsChecked}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <span className="text-center">
        Already have an account?
        <Button
          type="ghost"
          className="text-danger px-1"
          onClick={() => navigate("/sign-in")}
        >
          Login
        </Button>
      </span>
    </>
  );
};
export default FirstPage;
