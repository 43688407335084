import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ProfileProvider } from "./Context/ProfileContext";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ProfileProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ProfileProvider>,
  document.getElementById("root")
);
