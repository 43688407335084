import React, { useContext } from "react";
import { emitMessage } from "../Services/RedirectFunctions";
import LoginUser from "../Services/Login";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ProfileContext } from "../Context/ProfileContext";
import Registration from "../Services/Registeration";

const GoogleSignInBtn = ({ isSocialLogin, setApiError }) => {
  const navigate = useNavigate();
  const { profile, getUserData } = useContext(ProfileContext);

  const routes = {
    1: "/verify-mobile",
    2: "/due-date",
    3: "/children-details",
    4: "/children-details",
    5: "/due-date",
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await LoginUser.loginUser(
        { googleAuthToken: tokenResponse.access_token },
        profile?.apiKey
      );
      if (res?.status === 200) {
        if (res?.data?.user?.mobile && res?.data?.user?.c_code) {
          emitMessage({ token: res?.data?.token, page: profile?.page }, res?.data?.origin);
        } else {
          getUserData("token", res?.data?.token);
          getUserData("origin", res?.data?.origin);
          getUserData("userId", res?.data?.user?.sk_user_id);
          navigate("/verify-mobile");
        }
      } else setApiError(res?.message);
    },
  });

  const ssoRegistration = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          }
        );
        if (userInfo?.data?.email) {
          const res = await Registration.registerUser(
            { ...profile, email: userInfo?.data?.email },
            true,
            profile?.apiKey
          );
          if (res?.status === 201) {
            getUserData("origin", res?.data?.origin);
            getUserData("token", res?.data?.token);
            navigate(profile?.i_am ? routes[profile?.i_am] : "/verify-mobile");
          } else setApiError(res?.message)          
          getUserData("email", userInfo?.data?.email);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  return (
    <>
      {isSocialLogin ? (
        <Button className="social-login-btn" onClick={() => ssoRegistration()}>
          <img src="../Assets/images/Google.svg" alt="google-logo" />
        </Button>
      ) : (
        <Button className="social-login-btn" onClick={() => login()}>
          <img src="../Assets/images/Google.svg" alt="google-logo" />
        </Button>
      )}
    </>
  );
};

export default GoogleSignInBtn;
