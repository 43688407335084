import apiUrl from "../config/btpApi";
import axios from "axios";


const LoginUser = {
  loginUser: async (data, key) => {    
    try {
      const response = await axios.post(
        apiUrl.loginUrl,
        data,
        {
          headers: {
            "x-api-key": key,
          },
        }
      );
    
      return response;
    } catch (err) {
      return err.response.data;;
    }
  }
};

export default LoginUser;
