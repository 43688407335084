// const coreVerifyApi = process.env.REACT_APP_CORE_VERIFY_API;
const coreApi = process.env.REACT_APP_CORE_API + "api/users/v1/";
const coreLoginApi = process.env.REACT_APP_CORE_API + "api/sso/v1/";
const apiUrl = {
  verifyEmail: coreApi + 'verify-email',
  registerationUrl: coreApi + 'register',
  verifyOTP: coreApi + 'verify-otp',
  forgotPassword: coreApi + 'forgot-password',
  resetPassword: coreApi + 'reset-password',
  resendOTP: coreApi + 'resend-otp',
  resetPassword: coreApi + 'reset-password',
  verifyPhoneOTP: coreApi + 'verify-phone-otp',
  requestPhoneOtp: coreApi + 'request-phone-otp',
  loginUrl: coreLoginApi + 'login',
  requestOTP: coreApi + 'request-otp',
  changePassword: coreApi + 'change-password-v2',
  updateUser: coreApi,
  childrenDetails: coreApi + 'children',
};

export default apiUrl;

