import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Form, Typography, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import OnBoarding from "../Services/OnBoarding";


const { Title, Text } = Typography;

const passwordRules = [
  {
    validator: (password) => password.length >= 10,
    message: "Minimum 10 characters",
  },
  {
    validator: (password) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/.test(password),
    message: "Must contain at 1 lowercase, 1 uppercase, and 1 number",
  },
];

const NewPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { profile, getUserData } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showRules, setShowRules] = useState(false);
  const [allRulesPassed, setAllRulesPassed] = useState(false);
  const [apiError, setApiError] = useState("");

  const checkPasswordRules = () => {
    const passed = passwordRules.every((rule) => rule.validator(password));
    setAllRulesPassed(passed);
    passed && getUserData("password", password);
  };

  useEffect(() => checkPasswordRules(), [password]);

  const updatePassword = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async () => {
        const res = await OnBoarding.resetPassword(
          {
            password: password,
            otp: profile?.otp,
            email: profile?.email
          },
          profile.token
        );
        if (res?.status === 200) {
          navigate("/sign-in");
        } else
          setApiError(res?.message || "Something went wrong please try again!");
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Title level={4}>Create New Password</Title>
      <div className="w-100 mt-4">
        <Form form={form} layout="vertical">
          <Form.Item
            name="password"
            label="Password"
            validateDebounce={1500}
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onFocus={() => setShowRules(true)}
              onBlur={!password ? () => setShowRules(false) : null}
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            required
            rules={[
              // { required: true, message: "Confirm Your Password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              disabled={!allRulesPassed}
              onFocus={() => setShowRules(true)}
              onBlur={!password ? () => setShowRules(false) : null}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>
          {showRules && (
            <>
              {password ? (
                <div>
                  {passwordRules?.map((rule, index) => (
                    <>
                      <Text
                        key={index}
                        type={rule.validator(password) ? "success" : "danger"}
                      >
                        {rule.validator(password) ? (
                          <CheckCircleOutlined />
                        ) : (
                          <CloseCircleOutlined />
                        )}{" "}
                        {rule.message}
                      </Text>
                      <br />
                    </>
                  ))}
                </div>
              ) : (
                <div>
                  {passwordRules?.map((rule, index) => (
                    <>
                      <Text key={`rule-${index}`} type="secondary">
                        <InfoCircleOutlined /> {rule.message}
                      </Text>
                      <br />
                    </>
                  ))}
                </div>
              )}
            </>
          )}

          {apiError && (
            <Text type="danger">
              <CloseCircleOutlined /> {apiError}
            </Text>
          )}

          <Form.Item>
            <Button
              type="primary"
              className="w-100 mt-3"
              danger
              onClick={updatePassword}
              loading={loading}
              // disabled={password !== confirmPassword}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default NewPassword;
