import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Typography } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ProfileContext } from "../Context/ProfileContext";
import Verify from "../Services/Verification";
import OTPInput, { ResendOTP } from "otp-input-react";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";

import OnBoarding from "../Services/OnBoarding";

import { emitMessage } from "../Services/RedirectFunctions";

const { Title, Paragraph, Text } = Typography;

const MobileVerifaction = () => {
  const [form] = Form.useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { getUserData, profile } = useContext(ProfileContext);

  useEffect(() => {
    getUserData("c_code", "in");
  }, [])

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");

  const renderButton = (buttonProps) => {
    const originalOnClick = buttonProps.onClick;
    const mergedOnClick = (event) => {
      if (originalOnClick) {
        originalOnClick(event);
      }
      handleRequestOtp();
    };
    buttonProps.onClick = mergedOnClick;
    return (
      <button className="text-btn p-0 mt-3" {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend OTP in ${buttonProps.remainingTime} sec`
          : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const handlePhone = (phone, country, formattedValue) => {
    getUserData("mobile", phone);
    getUserData("c_code", country.countryCode);
    setFormattedPhoneNumber(formattedValue);
  };

  const handleRequestOtp = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const res = await Verify.requestPhoneOtp(
          { phone: profile?.mobile },
          profile?.token
        );
        if (res.status === 200) {
          setOtpSent(true);
        } else
          setApiError(res?.message || "Please try again, something went wrong");
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    const res = await Verify.verifyMobileOtp({ otp: otp }, profile?.token);
    if (res?.status === 200) {
      await OnBoarding.updateUser(
        {
          sk_user_id: profile?.userId,
          mobile: profile?.mobile,
          c_code: profile?.c_code,
          mobile_verification_status: 1,
        },
        profile?.token
      );
      emitMessage({ token: profile?.token, page: profile?.page }, profile?.origin);
      form.resetFields();
    } else
      setApiError(res?.message || "Please try again, something went wrong");
    setLoading(false);
  };

  const handleForeignNumber = async () => {
    setLoading(true);
    const res = await OnBoarding.updateUser(
      {
        sk_user_id: profile?.userId,
        mobile: profile?.mobile,
        c_code: profile?.c_code,
      },
      profile?.token
    );
    if (res?.status === 200) {
      emitMessage({ token: profile?.token, page: profile?.page }, profile?.origin);
      form.resetFields();
    } else
      setApiError(res?.message || "Please try again, something went wrong");
    setLoading(false);
  };

  const handleEdit = () => {
    setOtpSent(false);
    setApiError("");
  }

  return (
    <>
      {otpSent ? (
        <>
          <Title level={4}>Verify your phone number</Title>
          <Paragraph type="secondary">
            Enter the Otp Sent to{" "}
            <Text className="cursor-pointer" onClick={() => handleEdit()}>
              {formattedPhoneNumber} <EditOutlined />{" "}
            </Text>
          </Paragraph>
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            className="otp-box mt-4 px-1"
          />
          <ResendOTP
            renderButton={renderButton}
            renderTime={renderTime}
            className="mb-0"
          />
          {apiError && (
            <Text type="danger">
              <CloseCircleOutlined /> {apiError}
            </Text>
          )}
          <div className="d-flex ">
            <Button
              type="primary"
              className="w-100 mt-3"
              danger
              onClick={handleVerifyOtp}
            >
              Verify
            </Button>
          </div>
        </>
      ) : (
        <>
          {" "}
          <Form form={form} className="form-container w-100">
            <Title level={4}>What is your phone number</Title>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please enter your phone number" },
                // { validator: validatePhoneNumber },
              ]}
            >
              <PhoneInput
                country={"in"}                
                onChange={(phone, country, _, formattedValue) =>
                  handlePhone(phone, country, formattedValue)
                }
                placeholder="(91) 99000-99000"
              />
            </Form.Item>
            {apiError && (
              <Text type="danger">
                <CloseCircleOutlined /> {apiError}
              </Text>
            )}
            {profile?.c_code?.toLowerCase() === "in" ? (
              <Button
                className="w-100"
                type="primary"
                danger
                onClick={handleRequestOtp}
                loading={loading}
              >
                Send Otp
              </Button>
            ) : (
              <Button
                className="w-100"
                type="primary"
                danger
                onClick={handleForeignNumber}
                loading={loading}
              >
                Finish
              </Button>
            )}
          </Form>
          {/* {profile?.i_am && (
            <span className="mt-4 d-flex w-100 align-items-center justify-content-center">
              <Button
                type="text"
                danger
                className="back-btn d-flex align-items-center"
                onClick={() => navigate(-1)}
              >
                <RollbackOutlined /> Back
              </Button>
            </span>
          )} */}
        </>
      )}
    </>
  );
};

export default MobileVerifaction;
