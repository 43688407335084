import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Form, Typography, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleSignInBtn from "../Components/GoogleSignInBtn";
import AppleSignInBtn from "../Components/AppleSignInBtn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginUser from "../Services/Login";
import { ProfileContext } from "../Context/ProfileContext";
import { emitMessage } from "../Services/RedirectFunctions";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const SignIn = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();

  const registeredFrom = new URLSearchParams(search).get("registered_from");
  const apiKey = new URLSearchParams(search).get("apiKey");
  const page = new URLSearchParams(search).get("page");

  const { getUserData, profile } = useContext(ProfileContext);

  useEffect(() => {
    if (!profile.registred_from) {
      getUserData("registred_from", registeredFrom);
    }
  }, [registeredFrom]);

  useEffect(() => {
    if (!profile.apiKey) {
      getUserData("apiKey", apiKey);
    }
  }, [apiKey]);

  useEffect(() => {
    if (!profile.page) {
      getUserData("page", page);
    }
  }, [page]);

  const handleSignIn = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        setApiError("");
        const res = await LoginUser.loginUser(
          { email: profile?.email, password: profile?.password },
          profile?.apiKey
        );
        setLoading(false);
        if (res?.status === 200) {
          if (res?.data?.user?.mobile && res?.data?.user?.c_code) {
            emitMessage({ token: res?.data?.token, page: profile?.page }, res?.data?.origin);
            form.resetFields();
          } else {
            getUserData("token", res?.data?.token);
            getUserData("origin", res?.data?.origin);
            getUserData("userId", res?.data?.user?.sk_user_id);
            navigate("/verify-mobile");
          }
        } else
          setApiError(
            res?.message || "Something went wrong please refresh & try again"
          );
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Title level={4}>Let's Sign In</Title>
      <div className="w-100 mt-3">
        <Form form={form} layout="vertical">
          <Form.Item
            name={["user", "email"]}
            label="Email"
            required
            validateDebounce={2000}
            rules={[
              {
                type: "email",
                message: `${profile?.email} is not a valid email`,
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              onChange={(e) => getUserData("email", e.target.value)}
              onFocus={() => setApiError("")}
              placeholder="Enter your email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            className="mb-1 mt-4"
          >
            <Input.Password
              onChange={(e) => getUserData("password", e.target.value)}
              onFocus={() => setApiError("")}
              placeholder="Enter your password"
            />
          </Form.Item>

          <Form.Item className="text-right mt-1">
            <Button
              type="link"
              danger
              className="login-form-forgot p-0"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </Button>
          </Form.Item>

          {apiError && (
            <Text type="danger">
              <CloseCircleOutlined /> {apiError}
            </Text>
          )}

          <Form.Item>
            <Button
              type="primary"
              className="w-100 mt-3"
              danger
              onClick={() => handleSignIn()}
              loading={loading}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
        <Divider className="my-4" plain>
          <em>Or</em>
        </Divider>
        <span className="d-flex justify-content-between align-items-center">
          <GoogleOAuthProvider clientId="285015230290-8hf0spbdpceq31bjbgc80bpg3e97i1qd.apps.googleusercontent.com">
            <GoogleSignInBtn setApiError={setApiError} />
          </GoogleOAuthProvider>
          <AppleSignInBtn setApiError={setApiError} />
        </span>
      </div>
      <span className="text-center mt-4">
        Don't have an account?
        <Button
          type="ghost"
          className="text-danger px-1"
          onClick={() => navigate("/")}
        >
          Register
        </Button>
      </span>
    </>
  );
};

export default SignIn;
