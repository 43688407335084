import Cookies from "js-cookie";

export async function emitMessage(message, origin) {
  const url = Cookies.get("url");  
  if (url) {
    message.article = true;
    window.parent.postMessage(message, origin);
    // const demoUrl = url + "?cache=0";
    // window.open(demoUrl, "_blank");
    Cookies.remove("url");
  } else {
    window.parent.postMessage(message, origin);
  }
  window.close();
}
