import React, { useContext } from "react";
import AppleSignin from "react-apple-signin-auth";
import LoginUser from "../Services/Login";
import { emitMessage } from "../Services/RedirectFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ProfileContext } from "../Context/ProfileContext";

const AppleSignInBtn = ({ setApiError }) => {
  const navigate = useNavigate();
  const { profile, getUserData } = useContext(ProfileContext);

  const handleAppleLogin = async (data) => {
    try {
      const res = await LoginUser.loginUser(
        {
          appleIdToken: data.authorization.id_token,
        },
        profile?.apiKey
      );
      if (res?.status === 200) {
        if (res?.data?.user?.mobile && res?.data?.user?.c_code) {
          emitMessage({ token: res?.data?.token }, res?.data?.origin);
        } else {
          getUserData("token", res?.data?.token);
          getUserData("origin", res?.data?.origin);
          getUserData("userId", res?.data?.user?.sk_user_id);
          navigate("/verify-mobile");
        }
      } else setApiError(res?.message);
    } catch (error) {
      console.log("Google Login Failed");
      // setErrors({ login: "Login failed. Please try again." });
    }
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: "com.beingtheparent.auth",
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: "email name",
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: "https://core.beingtheparent.com",
        /** State string that is returned with the apple response */
        state: "state",
        /** Nonce */
        nonce: "nonce",
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="light"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      // buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={handleAppleLogin} // default = undefined
      /** Called upon signin error */
      onError={(error) => console.error(error)} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      iconProp={{ style: { marginTop: "10px" } }} // default = undefined
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(props) => (
        <Button className="social-login-btn" {...props}>
          <img src="../Assets/images/Vector.svg" alt="apple-logo" />
        </Button>
      )}
    />
  );
};

export default AppleSignInBtn;
