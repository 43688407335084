import axios from "axios";
import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import Cookies from "js-cookie";

const token = Cookies.get("accesstoken");
const userToken = Cookies.get("userToken");

const OnBoarding = {
  // updateUserDetails: async (data) => {

  //   try {
  //     const response = await BTPAuth.fetchData(
  //       apiUrl.updateUser,
  //       "patch",
  //       {},
  //       [data]
  //     );
  //     return response;
  //   } catch (err) {
  //     return err.response.data;
  //   }
  // },
  fetchUser: async (token) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updateUser,
        "get",
        {},
        {},
        token
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  updateUser: async (data, token) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updateUser,
        "patch",
        {},
        { ...data },
        token
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  resetPassword: async (data, token) => {
    console.log("tokenn", token);
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.resetPassword,
        "post",
        {},
        { ...data },
        token
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  addChildrenDetails: async (data, token) => {
    try {
      const response = await axios.patch(apiUrl.childrenDetails, data, {
        headers: {
          accesstoken: token,
        },
      });
      return response;
    } catch (err) {
      return err.response.data;
    }
  },
};

export default OnBoarding;
