import React, { useContext, useState } from "react";
import { Button, Input, Form, Typography, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import {
  RollbackOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ProfileContext } from "../Context/ProfileContext";
import OTPInput, { ResendOTP } from "otp-input-react";
import Verify from "../Services/Verification";

const { Title, Paragraph, Text } = Typography;

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { getUserData, profile } = useContext(ProfileContext);
  const [OTP, setOTP] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  const renderButton = (buttonProps) => {
    const originalOnClick = buttonProps.onClick;
    const mergedOnClick = (event) => {
      if (originalOnClick) {
        originalOnClick(event);
      }
      handleResendOtp();
    };
    buttonProps.onClick = mergedOnClick;

    return (
      <button className="text-btn p-0 mt-3" {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend OTP in ${buttonProps.remainingTime} sec`
          : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const handleSendOtp = async () => {
    setLoading(true)
    const res = await Verify.requestOtp(profile?.email);
    if (res.status === 200) {
      setOtpSent(true);
    }
    setLoading(false)
  };

  const handleResendOtp = async () => {
    const response = await Verify.resendOtp(profile?.email);
  };

  const handleVerifyOtp = async () => {
    setApiError("");
    setLoading(true);
    const response = await Verify.verifyOtp({
      email: profile?.email,
      otp: OTP,
    });
    
    if (response.status === 200) {
      console.log("api-response", response);
      getUserData("token", response?.data?.token);
      getUserData("otp", OTP);
      navigate("/new-password");      
    } else setApiError(response?.message);
    setLoading(false);
  };

  const handleEdit = () => {
    setOtpSent(false);
    setApiError("");
  }

  return (
    <>
      <Title level={4}>Verify Your Email</Title>
      <div className="w-100 mt-4">
        <span className="vertically-center">
          {!otpSent ? (
            <>
              <Form form={form} layout="vertical">
                <Form.Item
                  name="email"
                  required
                  validateDebounce={2000}
                  label="Enter your Email"
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    suffix={
                      <Button
                        type="link"
                        onClick={handleSendOtp}
                        danger
                        loading={loading}
                        // disabled={!form.isFieldValidating("email")}
                      >
                        Send Otp
                      </Button>
                    }
                    placeholder="xyz@gmail.com"
                    onChange={(e) => getUserData("email", e.target.value)}
                  />
                </Form.Item>
              </Form>
              <span className="mt-4 d-flex w-100 align-items-center justify-content-center">
                <Button
                  type="text"
                  danger
                  className="back-btn d-flex align-items-center"
                  onClick={() => navigate(-1)}
                >
                  <RollbackOutlined /> Back
                </Button>
              </span>
            </>
          ) : (
            <>
              <Paragraph type="secondary">
                Enter the Otp Sent to{" "}
                <Text
                  className="cursor-pointer"
                  onClick={() => handleEdit()}
                >
                  {profile?.email} <EditOutlined />{" "}
                </Text>
              </Paragraph>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                className={`otp-box mt-3 px-1`}
              />
              <ResendOTP renderButton={renderButton} renderTime={renderTime} />
              {apiError && (
                <Text type="danger">
                  <CloseCircleOutlined /> {apiError}
                </Text>
              )}
              <Button
                type="primary"
                className="w-100 mt-3"
                danger
                onClick={handleVerifyOtp}
                disabled={OTP?.length !== 4}
                loading={loading}
              >
                Verify
              </Button>
            </>
          )}
        </span>
      </div>
    </>
  );
};

export default ForgotPassword;
