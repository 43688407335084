import validator from "validator";
import moment from "moment";

export const isRequired = (value) => {
  if (!value.toString().trim().length) {
    return "Required";
  }
};

export const lt = (value, props) => {
  if (!value.toString().trim().length > props.maxLength) {
    return (
      <span className="error">
        The value exceeded {props.maxLength} symbols.
      </span>
    );
  }
};

export const isPassword = (value, props, components) => {
  if (!value) {
    return "Please enter your password";
  }
};

export const isPasswordValid = (value, props, components) => {
  if (!value) {
    return "Please enter your password";
  } else if (value.length < 8) {
    return "Please match the pasword rules";
  }
};

export const isEmail = (value) => {
  if (!value) {
    return "Please enter your email";
  } else if (!validator.isEmail(value)) {
    return "Please enter a correct email";
  }
};

export const isName = (value) => {
  if (!value.toString().trim().length) {
    return "Please enter your name";
  } else if (value.length < 3) {
    return "Your name should have atleast 3 letters";
  }
};
export const isDate = (value) => {
  if (!value.toString().trim().length) {
    return "Please select your birthdate";
  }
};

export const isValidPincode = (pincode) => {
  const pincodeRegex = /^[1-9][0-9]{5}$/; 
  if (pincode !== "") {
    if (!pincode.match(pincodeRegex)) {
      return false;
    }
  }
  return true;
};

export const isValidPhoneNumber = (number) => {
  const phoneNumberRegex = /^(\d{1,4})?(\d{10})$/;

  if (number !== "") {
    if (!number.match(phoneNumberRegex)) {
      return "Please enter a valid phone number";
    }
  }
  return "";
};

export const isValidDueDate = (date) => {
  const today = moment(new Date()).format("YYYY-MM-DD");
  const tempDate = moment(date).format("YYYY-MM-DD");
  const temp = new Date();
  const nineMonthsLater = temp.setMonth(temp.getMonth() + 10);
  const tempNineMonths = moment(nineMonthsLater).format("YYYY-MM-DD");
  if (today > tempDate) {
    return "Due date can't be less than today";
  } else return "";
};

export const isValidBirthDate = (date) => {
  const today = moment();
  const tempDate = moment(date, "YYYY-MM-DD");

  if (date === null || date === undefined || date === "") {
    return "Please enter the Date of birth";
  }
  if (today < tempDate) {
    return "Birth date can't be greater than today";
  }
  if (today.diff(tempDate, "years") > 12) {
    return "Age should be less than or equal to 12";
  }
  return "";
};

export const isValidAge = (date) => {
  const birthDate = new Date();
  birthDate.setFullYear(birthDate.getFullYear() - 18);
  const tempBirthDate = moment(birthDate).format("YYYY-MM-DD");
  const tempDate = moment(date).format("YYYY-MM-DD");
  if (date === null || date === undefined || date === "") {
    return "Date can not be empty";
  }
  if (tempDate > tempBirthDate) {
    return "You must be 18+";
  }
};

export const isValidOTP = (otp) => {
  if (otp.length < 4) {
    return "Please enter the 4 digit OTP";
  }
};
