import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import Cookies from "js-cookie";

const Verify = {
  verifyEmail: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.verifyEmail,
        "post",
        { "client-platform": "web" },
        data
      );
      Cookies.set("accesstoken", response?.data?.token);
      console.log("api token", response?.data?.token);
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  verifyOtp: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.verifyOTP,
        "post",
        {},
        data
      );
      Cookies.set("accesstoken", response?.data?.token);
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  requestOtp: async (email) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.requestOTP + "?email=" + email,
        "post",
        {}
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  forgotPassword: async (email) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.forgotPassword,
        "post",
        {},
        { email: email }
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  resendOtp: async (email) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.resendOTP,
        "post",
        {},
        { email: email }
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  requestPhoneOtp: async (phone, token) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.requestPhoneOtp,
        "post",
        {},
        phone,
        token
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  verifyMobileOtp: async (data, token) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.verifyPhoneOTP,
        "post",
        {},
        data,
        token
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },
};

export default Verify;
