import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { ProfileContext } from "../Context/ProfileContext";

const { Title } = Typography;

const StageSelection = () => {
  const [selectedStage, setSelectedStage] = useState(null);
  const navigate = useNavigate();

  const { getUserData, profile } = useContext(ProfileContext);

  const handleStageSelection = async (stage) => {
    getUserData("i_am", stage);
    setSelectedStage(stage);
  };

  useEffect(() => {    
    if (Object.keys(profile).length > 0) {
      console.log(profile?.stage)
      setSelectedStage(profile?.i_am)
    }
  }, []);

 

  return (
    <div className="w-100 h-100">
      <Title level={4} className="mb-4">
        Help us customize your journey with us
      </Title>
      <div className="d-flex align-items-center flex-column">
        <div
          className={`w-100 mb-3 stage-btn ${
            selectedStage === "1" ? "active" : ""
          }`}
          // shape="round"
          size="large"
          onClick={() => handleStageSelection("1")}
        >
          <span className="">I'm planning</span>
          <img src="../Assets/images/planning4.svg" alt="planning" />
        </div>
        <div
          className={`w-100 mb-3 stage-btn ${
            selectedStage === "2" ? "active" : ""
          }`}
          // shape="round"
          size="large"
          onClick={() => handleStageSelection("2")}
        >
          <span className="">I'm expecting</span>
          <img src="../Assets/images/planning2.svg" alt="expecting" />
        </div>
        <div
          className={`w-100 mb-3 stage-btn ${
            selectedStage === "3" ? "active" : ""
          }`}
          // shape="round"
          size="large"
          onClick={() => handleStageSelection("3")}
        >
          <span className="">I'm a parent</span>
          <img src="../Assets/images/Group-2.svg" alt="parent" />
        </div>
        <div
          className={`w-100 mb-3 stage-btn ${
            selectedStage === "4" ? "active" : ""
          }`}
          // shape="round"
          size="large"
          onClick={() => handleStageSelection("4")}
        >
          <span className="">I'm planning for another</span>
          <img src="../Assets/images/Group-3.svg" alt="parent" />
        </div>
        <div
          className={`w-100 mb-3 stage-btn ${
            selectedStage === "5" ? "active" : ""
          }`}
          // shape="round"
          size="large"
          onClick={() => handleStageSelection("5")}
        >
          <span className="">I'm expecting another</span>
          <img src="../Assets/images/Group-4.svg" alt="parent" />
        </div>
        <Button
          className="w-100"
          // shape="round"
          type="primary"
          danger
          onClick={() => navigate("/sign-up")}
          disabled={!profile?.i_am}
        >
          Submit
        </Button>
        <span className="mt-4 d-flex w-100 align-items-center justify-content-center">
          <Button
            type="text"
            danger
            className="back-btn d-flex align-items-center"
            onClick={() => navigate(-1)}
            
          >
            <RollbackOutlined /> Back
          </Button>
        </span>
      </div>
    </div>
  );
};

export default StageSelection;
