import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Form, Typography, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import GoogleSignInBtn from "../Components/GoogleSignInBtn";
import AppleSignInBtn from "../Components/AppleSignInBtn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  RollbackOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ProfileContext } from "../Context/ProfileContext";
import OTPInput, { ResendOTP } from "otp-input-react";
import Verify from "../Services/Verification";
import Registration from "../Services/Registeration";

const { Title, Text, Paragraph } = Typography;

const passwordRules = [
  {
    validator: (password) => password.length >= 10,
    message: "Minimum 10 characters",
  },
  {
    validator: (password) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/.test(password),
    message: "Must contain at 1 lowercase, 1 uppercase, and 1 number",
  },
];

const SignIn = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { profile, getUserData } = useContext(ProfileContext);

  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [allRulesPassed, setAllRulesPassed] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");  
  const [userId, setUserId] = useState(0);

  const checkPasswordRules = () => {
    const passed = passwordRules.every((rule) => rule.validator(password));
    setAllRulesPassed(passed);
    passed && getUserData("password", password);
  };

  useEffect(() => {
    checkPasswordRules();
  }, [password]);

  const handleSignUp = async () => {
    console.log("profile", profile);

    navigate("/verify-mobile");
  };

  const routes = {
    1: "/verify-mobile",
    2: "/due-date",
    3: "/children-details",
    4: "/children-details",
    5: "/due-date",
  };

  const handleRequestOtp = () => {
    form
      .validateFields()
      .then(async () => {
        setApiError("");
        setShowRules(false);
        setLoading(true);
        const res = await Registration.registerUser(
          profile,
          false,
          profile?.apiKey
        );
        if (res.status === 201) {
          setOtpSent(true);
          setUserId(res?.data?.sk_user_id);
          getUserData("origin", res?.data?.origin);
          getUserData("token", res?.data?.unverifiedToken);
        } else {
          setApiError(
            res?.message
              ? res?.message
              : "Something went wrong please try again"
          );
          // setTimeout(() => {

          // }, 1500)
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => setLoading(false));
  };

  const handleVerifyOtp = async () => {
    const res = await Verify.verifyEmail({ sk_user_id: userId, otp: otp });

    if (res.status === 200) {
      getUserData("userId", res?.data?.user?.sk_user_id);
      getUserData("token", res?.data?.token);
      navigate(profile?.i_am ? routes[profile?.i_am] : "/verify-mobile");
    } else if (res?.status === "error") {
      setApiError(res?.message);
    }
  };

  const handleResendOtp = async () => {
    const response = await Verify.resendOtp(profile?.email);
  };

  const renderButton = (buttonProps) => {
    const originalOnClick = buttonProps.onClick;
    const mergedOnClick = (event) => {
      if (originalOnClick) {
        originalOnClick(event);
      }
      handleResendOtp();
    };
    buttonProps.onClick = mergedOnClick;
    return (
      <button className="text-btn p-0 mt-3" {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend OTP in ${buttonProps.remainingTime} sec`
          : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const handleShowRules = () => {
    setApiError("");
    setShowRules(true);
  };

  const handleEdit = () => {
    setOtpSent(false);
    setApiError("");
  }

  return (
    <>
      {otpSent ? (
        <>
          <Title level={4}>Verify your e-mail</Title>
          <Paragraph type="secondary">
            Enter the Otp Sent to{" "}
            <Text className="cursor-pointer" onClick={() => handleEdit()}>
              {profile?.email} <EditOutlined />{" "}
            </Text>
          </Paragraph>
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            className="otp-box mt-4 px-1"
          />
          <ResendOTP
            renderButton={renderButton}
            renderTime={renderTime}
            className="mb-0"
          />

          {apiError && (
            <Text type="danger">
              <CloseCircleOutlined /> {apiError}
            </Text>
          )}

          <div className="d-flex ">
            <Button
              type="primary"
              className="w-100 mt-3"
              danger
              onClick={handleVerifyOtp}
              disabled={otp?.length !== 4}
            >
              Verify
            </Button>
          </div>
        </>
      ) : (
        <>
          <Title level={4}>Let's Sign Up</Title>
          <div className="w-100 mt-4">
            <Form form={form} layout="vertical">
              <Form.Item
                name="email"
                label="Email"
                required
                validateDebounce={1500}
                rules={[
                  {
                    type: "email",
                    message: `${profile?.email} is not valid email address`,
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input
                  onChange={(e) => getUserData("email", e.target.value)}
                  onFocus={() => setApiError("")}
                  placeholder="Enter your email"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                required
                className="mb-3"
                validateDebounce={1500}
              >
                <Input.Password
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onFocus={handleShowRules}
                  placeholder="Enter your password"
                  // onBlur={handleShowRules}
                />
              </Form.Item>

              {showRules && (
                <>
                  {password ? (
                    <div>
                      {passwordRules?.map((rule, index) => (
                        <>
                          <Text
                            key={`signup-${index}`}
                            type={
                              rule.validator(password) ? "success" : "danger"
                            }
                          >
                            {rule.validator(password) ? (
                              <CheckCircleOutlined />
                            ) : (
                              <CloseCircleOutlined />
                            )}{" "}
                            {rule.message}
                          </Text>
                          <br />
                        </>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {passwordRules?.map((rule, index) => (
                        <>
                          <Text key={`signup2-${index}`} type="secondary">
                            <InfoCircleOutlined /> {rule.message}
                          </Text>
                          <br />
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}

              {apiError && (
                <Text type="danger">
                  <CloseCircleOutlined /> {apiError}
                </Text>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  className="w-100 mt-3"
                  danger
                  onClick={handleRequestOtp}
                  loading={loading}
                  disabled={!allRulesPassed}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
            <Divider className="my-3" plain>
              <em>Or</em>
            </Divider>
            <span className="d-flex justify-content-between align-items-center">
              <GoogleOAuthProvider clientId="285015230290-8hf0spbdpceq31bjbgc80bpg3e97i1qd.apps.googleusercontent.com">
                <GoogleSignInBtn isSocialLogin={true} setApiError={setApiError} />
              </GoogleOAuthProvider>
              <AppleSignInBtn setApiError={setApiError} />
            </span>
          </div>
          <span className="text-center mt-3">
            Already have an account?
            <Button
              type="ghost"
              className="text-danger px-1"
              onClick={() => navigate("/sign-in")}
            >
              Login
            </Button>
          </span>
          <span className="mt-4 d-flex w-100 align-items-center justify-content-center">
            <Button
              type="text"
              danger
              className="back-btn d-flex align-items-center"
              onClick={() => navigate(-1)}
            >
              <RollbackOutlined /> Back
            </Button>
          </span>
        </>
      )}
    </>
  );
};

export default SignIn;
