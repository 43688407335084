import SignUp from "../Pages/SignUp";
import "../Pages/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import ForgotPassword from "../Pages/ForgotPassword";
import StageSelection from "../Pages/StageSelection";
import ChildDetails from "../Pages/ChildrenDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import NewPassword from "../Pages/NewPassword";
import DueDate from "../Pages/DueDate";
import FirstPage from "../Pages/FirstPage";
import SignIn from "../Pages/SignIn";
import MobileVerifaction from "../Pages/MobileVerification";

function MainPage() {
  return (
    <>
      <span className="desktop-container d-flex flex-column  justify-content-center">        
        <BrowserRouter className="form-container">
          <Routes>            
            <Route path="/verify-mobile" element={<MobileVerifaction />} />
            <Route path="/" element={<FirstPage />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/select-stage" element={<StageSelection />} />            
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/due-date" element={<DueDate />} />
            <Route path="/children-details" element={<ChildDetails />} />            
          </Routes>
        </BrowserRouter>
      </span>
    </>
  );
}

export default MainPage;
