import Cookies from "js-cookie";
import apiUrl from "../config/btpApi";
import axios from "axios";

const Registration = {
  registerUser: async (data, bool, apiKey) => {
    
    const temp = bool ? `?isSocialLogin=${bool}` : ""
    try {
      const response = await axios.post(
        apiUrl.registerationUrl + temp,
        data,
        {
          headers: {
            sso: apiKey,
          },
        }
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
  },
};

export default Registration;
