import React, { useContext, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Typography } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate} from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import OnBoarding from "../Services/OnBoarding";

const DueDate = () => {
  const [form] = Form.useForm();

  const minDate = moment().startOf("day");

  const { getUserData, profile } = useContext(ProfileContext);

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { Title, Text } = Typography;  

  const navigate = useNavigate();

  const handleContinue = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        setApiError("");
        const res = await OnBoarding.updateUser(
          {
            sk_user_id: profile?.userId,
            due_date_parent: profile?.due_date_parent,
          },
          profile?.token
        );
        if (res.status === 200) {
          navigate(
            profile?.i_am === "5" ? "/children-details" : "/verify-mobile"
          );
        } else
          setApiError(res?.message || "Something went wrong, please try again");
        setLoading(false);
      })
      .catch((errorInfo) => {        
        console.log("Validation failed:", errorInfo);
      });    
  };

  const disabledDate = (current) => {
    return current && current < minDate.startOf("day");
  };

  return (
    <>
      <Title level={4}>Tell us your due date</Title>
      <Form form={form} layout="vertical" className="w-100">
        <Form.Item
          label="Due Date"
          name="Due Date"
          rules={[{ required: true }]}
          validateDebounce={1500}
        >
          <DatePicker
            onChange={(date, dateString) =>
              getUserData("due_date_parent", dateString)
            }
            disabledDate={disabledDate}
            className="w-100"
          />
        </Form.Item>

        {apiError && (
          <Text type="danger">
            <CloseCircleOutlined /> {apiError}
          </Text>
        )}

        <Form.Item>
          <Button
            type="primary"
            className="w-100"
            danger
            onClick={handleContinue}
            loading={loading}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>      
    </>
  );
};
export default DueDate;
