import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Typography,
  Divider,
  Radio,
} from "antd";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import { DeleteOutlined } from "@ant-design/icons";
import OnBoarding from "../Services/OnBoarding";

const ChildDetails = () => {
  const [form] = Form.useForm();
  const { Title } = Typography;

  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);

  const [childData, setChildData] = useState([
    {
      child_name: "",
      dob: null,
      stage_of_parenting: profile?.i_am,
      gender: "",
      stage_status: 1,
    },
  ]);

  const handleContinue = () => {
    form
      .validateFields()
      .then(async () => {
        await form.getFieldsValue();        
        await OnBoarding.addChildrenDetails(childData, profile?.token);
        navigate("/verify-mobile");
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleAddChild = () => {
    form
      .validateFields()
      .then(() => {
        setChildData([
          ...childData,
          {
            child_name: "",
            dob: null,
            stage_of_parenting: profile?.i_am,
            gender: "",
            stage_status: 1,
          },
        ]);
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveChild = (index) => {
    setChildData((prevChildData) => {
      return prevChildData.filter((_, i) => i !== index);
    });
    form.resetFields([
      `childName_${index}`,
      `childDob_${index}`,
      `Gender_${index}`,
    ]);
  };

  const updateChildData = (key, value, index) => {
    setChildData((prevChildData) => {
      const updatedChildData = [...prevChildData];
      updatedChildData[index] = {
        ...updatedChildData[index],
        [key]: value,
      };
      return updatedChildData;
    });
  };

  return (
    <>
      <Title level={4}>Child Details</Title>
      {console.log("childData", childData)}
      <Form form={form} layout="vertical" className="w-100">
        {childData?.map((item, index) => (
          <React.Fragment key={index}>
            <Form.Item
              label="Child Name"
              name={`childName_${index}`}
              rules={[{ required: true, message: "Please enter child's name" }]}
            >
              {console.log("input", index, item)}
              <Input
                onChange={(e) =>
                  updateChildData("child_name", e.target.value, index)
                }
                value={item?.child_name}
              />
            </Form.Item>

            <Form.Item
              label="Child DOB"
              name={`childDob_${index}`}
              rules={[{ required: true, message: "Please select child's DOB" }]}
            >
              <DatePicker
                onChange={(date, dateString) =>
                  updateChildData("dob", dateString, index)
                }
                className="w-100"
              />
            </Form.Item>

            <Form.Item
              label="Gender"
              rules={[{ required: true }]}
              name={`Gender_${index}`}
              validateDebounce={1500}
            >
              <Radio.Group
                onChange={(e) =>
                  updateChildData("gender", e.target.value, index)
                }
              >
                <Radio value="Female">Female</Radio>
                <Radio value="Male">Male</Radio>
              </Radio.Group>
            </Form.Item>

            {childData?.length === index + 1 && childData?.length > 1 ? (
              <Form.Item className="text-end w-100">
                <Button
                  type="link"
                  danger
                  onClick={() => handleRemoveChild(index)}
                  className="pe-0"
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            ) : null}
            {childData?.length === index + 1 && (
              <Form.Item>
                <Button
                  type="link"
                  className="ps-0"
                  danger
                  onClick={handleAddChild}
                >
                  Add child
                </Button>
              </Form.Item>
            )}
            {childData?.length !== 0 && childData?.length !== index + 1 ? (
              <Divider />
            ) : null}
          </React.Fragment>
        ))}
      </Form>
      <Button type="primary" className="w-100" danger onClick={handleContinue}>
        Continue
      </Button>
    </>
  );
};

export default ChildDetails;
