import React, { createContext, useEffect, useState } from "react";
// import Profile from "../services/Profile";
// import Cookies from "js-cookie";
import propTypes from "prop-types";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  ProfileProvider.propTypes = {
    children: propTypes.any,
  };
  
  const [profile, setProfile] = useState({});

  const getUserData = (field, val) => {
    setProfile((profile) => ({...profile, [field]: val}))
  }

  return (
    <ProfileContext.Provider
      value={{ getUserData, profile }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
